import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import * as Clipboard from 'expo-clipboard';
import { Text, VStack } from 'native-base';
import { useEffect, useState } from 'react';
import { useSettings } from '..';
import { Button } from '../../components/ui';
import Curricula from '../../constants/Curricula';
import { Campaign, User } from '../../models/data';
import { Alert } from '../../models/view';
import { RootStackParamList } from '../../navigation';
import { useAppSelector } from '../../store';
import { generateShortUUID, openUrl } from '../../utils';
import { nativeAlert } from '../../utils/ui';
import {
  shouldShowBackgroundCheckAlert,
  shouldShowPostAssessmentAlert,
  shouldShowPreAssessmentAlert,
  shouldShowProgramSurveyAlert,
  shouldShowReenrollmentAlert,
  shouldShowSpringEnrollmentAlert,
  shouldShowSpringEnrollmentConfirmationAlert,
  shouldShowTrainingAlert,
  userIsStudent,
  userIsVolunteer,
} from './utils';

const backgroundCheckAlert: Alert = {
  id: generateShortUUID(),
  type: 'warning',
  title: 'Background Check',
  message: () => (
    <VStack space={4}>
      <Text>
        You need a background check to complete your registration and participate with RTSWS. In the next 48 hours you
        will receive an email inviting you to complete your background check through Verified First. Please check your
        spam box if you do not receive the email.
      </Text>
      <Text>This notification will disappear when your background check is returned and approved.</Text>
    </VStack>
  ),
  canClose: false,
};

const trainingAlert = (campaign: Campaign, getSettingValueByName: (name: string) => string): Alert => {
  // Fall back to generic training url and password if no curriculum is set
  let url = 'https://rockthestreetwallstreet.com/curriculum-training';
  let password = 'Training';

  const curriculum = Curricula.find((c) => c.name === campaign.curriculumSet);
  if (curriculum) {
    url = getSettingValueByName(curriculum.curriculum_url_slug);
    password = getSettingValueByName(curriculum.curriculum_password_slug);
  }

  return {
    id: generateShortUUID(),
    type: 'warning',
    title: 'Training',
    message: () => (
      <VStack space={4}>
        <Text>Volunteer Training needs to be completed.</Text>
        <Button
          text="Tap to view training curriculum"
          color="secondary"
          onPress={async () => {
            await Clipboard.setStringAsync(password);
            await nativeAlert(
              'Volunteer Training',
              `The password has been copied to the clipboard. Press "Okay" to continue.`,
            );
            openUrl(url);
            setTimeout(() => {
              Clipboard.setStringAsync('');
            }, 30000);
          }}
        />
        <Text>
          Password: <Text fontFamily="mono">{password}</Text>
        </Text>
        <Text>After completion, this notice will be removed within 3 days.</Text>
      </VStack>
    ),
    canClose: false,
  };
};

const makePreAssessmentAlert = (preAssessmentUrl: string): Alert => ({
  id: generateShortUUID(),
  type: 'success',
  title: 'Take the Pre-Assessment',
  message: () => <Button text="Open" color="secondary" onPress={() => openUrl(preAssessmentUrl)} />,
  canClose: false,
});

const makePostAssessmentAlert = (postAssessmentUrl: string): Alert => ({
  id: generateShortUUID(),
  type: 'success',
  title: 'Take the Post-Assessment',
  message: () => <Button text="Open" color="secondary" onPress={() => openUrl(postAssessmentUrl)} />,
  canClose: false,
});

const makeProgramSurveyAlert = (programSurveyUrl: string): Alert => ({
  id: generateShortUUID(),
  type: 'success',
  title: 'Take the Program Survey',
  message: () => <Button text="Open" color="secondary" onPress={() => openUrl(programSurveyUrl)} />,
  canClose: false,
});

const makeReenrollmentAlert = (currentUser: User): Alert => ({
  id: generateShortUUID(),
  type: 'warning',
  title: 'Re-enroll',
  message: () => {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;
    const yearString = `${currentYear}/${nextYear}`;

    const isStudent = userIsStudent(currentUser);
    return (
      <Button
        text={`Enroll for school year ${yearString}`}
        color="primary"
        onPress={() => navigation.navigate(isStudent ? 'StudentReenrollment' : ('VolunteerReenrollment' as any))}
      />
    );
  },
  canClose: false,
});

const makeSpringEnrollmentAlert = (currentUser: User): Alert => ({
  id: generateShortUUID(),
  type: 'success',
  title: 'Spring Enrollment',
  message: () => {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

    if (userIsStudent(currentUser)) {
      return (
        <Button
          text="Enroll For Spring Mentorship"
          color="primary"
          onPress={() => navigation.navigate('SpringEnrollment')}
        />
      );
    } else if (userIsVolunteer(currentUser)) {
      return (
        <Button text="Sign Up as a Mentor" color="primary" onPress={() => navigation.navigate('SpringEnrollment')} />
      );
    }
  },
  canClose: false,
});

const makeSpringEnrollmentConfirmationAlert = (): Alert => ({
  id: generateShortUUID(),
  type: 'success',
  title: 'Spring Enrollment',
  message: () => {
    return (
      <VStack space={4}>
        <Text>You are enrolled for Spring Mentorship!</Text>
      </VStack>
    );
  },
  canClose: false,
});

export const useAlerts = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const campaign = useAppSelector((state) => state.campaigns.currentCampaign);
  const programEvents = [...useAppSelector((state) => state.programEvents.programEvents)];
  const { registrationSubmitted } = useAppSelector((state) => state.registrations);
  const { getSettingValueByName } = useSettings();
  const firstEvent = programEvents.shift();
  const lastEvent = programEvents.pop();

  const [alerts, setAlerts] = useState<Alert[]>([]);

  useEffect(() => {
    const newAlerts: Alert[] = [];

    if (!currentUser) {
      setAlerts(newAlerts);
      return;
    }

    if (userIsVolunteer(currentUser) && campaign && currentUser.contact) {
      // Volunteer Background check
      if (shouldShowBackgroundCheckAlert(currentUser.contact)) {
        newAlerts.push(backgroundCheckAlert);
      }

      // Volunteer Training check
      if (shouldShowTrainingAlert(currentUser.contact)) {
        newAlerts.push(trainingAlert(campaign, getSettingValueByName));
      }
    }

    if (userIsStudent(currentUser)) {
      // Student pre-assessment
      if (campaign && shouldShowPreAssessmentAlert(campaign, firstEvent)) {
        newAlerts.push(makePreAssessmentAlert(campaign.preAssessmentLink));
      }

      // Student post-assessment
      if (campaign && shouldShowPostAssessmentAlert(campaign, lastEvent)) {
        newAlerts.push(makePostAssessmentAlert(campaign.postAssessmentLink));
      }
    }

    // End of campaign survey
    if (campaign && shouldShowProgramSurveyAlert(campaign)) {
      newAlerts.push(makeProgramSurveyAlert(campaign.endOfCourseSurvey));
    }

    // Reenrollment
    if (shouldShowReenrollmentAlert(currentUser, registrationSubmitted)) {
      newAlerts.push(makeReenrollmentAlert(currentUser));
    }

    // Spring enrollment
    if (shouldShowSpringEnrollmentAlert(currentUser, registrationSubmitted)) {
      newAlerts.push(makeSpringEnrollmentAlert(currentUser));
    }

    // Spring enrollment confirmation
    if (campaign && shouldShowSpringEnrollmentConfirmationAlert(currentUser, campaign)) {
      newAlerts.push(makeSpringEnrollmentConfirmationAlert());
    }

    setAlerts(newAlerts);
  }, [currentUser, campaign, registrationSubmitted]);

  return { alerts };
};
